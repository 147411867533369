<template>
  <div id="recharge-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2
            class="font-medium text-base"
            :class="isRTL ? 'ml-auto' : 'mr-auto'"
          >
            <app-i18n code="wallets.recharge"></app-i18n>
          </h2>
        </div>
        <div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
            <div class="col-span-12">
              <label for="rechargeAmount" class="form-label">{{
                i18n('wallets.rechargeAmount')
              }}</label>
              <input
                id="rechargeAmount"
                v-model="amount"
                type="number"
                class="form-control w-full"
              />
            </div>
          </div>
          <div class="modal-footer flex justify-center items-center gap-2">
            <AppButton
              id="submit-btn"
              type="button"
              data-dismiss="modal"
              class="btn bg-theme-36 text-white cursor-pointer"
              :class="!rechargeLoading ? 'w-28' : ''"
              :disabled="rechargeLoading"
              :loading="rechargeLoading"
              @click="doSubmit"
            >
              <strong>{{ i18n('wallets.recharge') }}</strong>
              <template v-slot:loading>
                <span>{{ i18n('common.loading') }}</span>
                <LoadingIcon
                  icon="three-dots"
                  color="#FFFFFF"
                  style="margin: 0 4px"
                />
              </template>
            </AppButton>
            <button
              type="button"
              data-dismiss="modal"
              class="btn bg-theme-31 text-black w-24"
            >
              {{ i18n(`common.cancel`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  name: 'app-recharge-modal',
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      amount: undefined
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      rechargeLoading: 'user/view/rechargeLoading'
    })
  },
  methods: {
    ...mapActions({
      doRecharge: 'user/view/doRecharge'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doSubmit() {
      if (!this.amount || this.amount <= 0) {
        Message.error(
          this.isRTL ? 'ادخل قيمه الشحن' : 'enter the recharge amount'
        )
        return false
      }
      if (!/^[0-9]*\.?[0-9]+$/.test(this.amount)) {
        Message.error(this.isRTL ? 'أدخل رقمًا صالحًا' : 'enter a valid number')
        return false
      }
      await this.doRecharge(parseFloat(this.amount))
      this.amount = undefined
      cash('#recharge-modal').modal('hide')
    }
  }
}
</script>
