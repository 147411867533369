<template>
  <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
      {{ i18n('routes.appWallet') }}
    </h2>
  </div>
  <div class="intro-y box rounded-xl p-5 mt-5">
    <section>
      <div class="flex justify-between items-center">
        <h6 class="caption">
          <app-i18n code="wallet.caption"></app-i18n>
        </h6>
        <div class="flex gap-4">
          <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#recharge-modal"
            class="btn bg-theme-36 text-white mb-2 flex items-center"
          >
            <app-i18n code="wallets.recharge"></app-i18n>
          </a>
          <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#withdraw-modal"
            class="btn bg-theme-31 text-black mb-2 flex items-center"
          >
            <app-i18n code="wallets.withdraw"></app-i18n>
          </a>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6 mt-2 py-3">
        <div class="col-span-12 sm:col-span-6 intro-y">
          <app-wallet-card
            v-if="wallet && !walletLoading"
            :amount="wallet['balance']"
            :label="i18n('wallet.fields.totalBalance')"
            icon="/images/icons/ic-wallet-1.png"
          ></app-wallet-card>
          <app-wallet-card-loading v-else />
        </div>
        <div class="col-span-12 sm:col-span-6 intro-y">
          <app-wallet-card
            v-if="wallet && !walletLoading"
            :amount="wallet['actualBalance']"
            :label="i18n('wallet.fields.availableBalance')"
            icon="/images/icons/ic-money-5.png"
          ></app-wallet-card>
          <app-wallet-card-loading v-else />
        </div>
      </div>
    </section>
  </div>
  <app-wallet-transaction-table
    user="app"
    userId="-1"
  ></app-wallet-transaction-table>
  <app-recharge-modal
    id="app"
    @reset-modal="showModal = false"
  ></app-recharge-modal>
  <app-withdraw-modal
    id="app"
    @reset-modal="showModal = false"
  ></app-withdraw-modal>
</template>

<script>
import WalletCard from '@/views/wallet/wallet-card.vue'
import WalletCardLoading from '@/views/wallet/wallet-card-loading.vue'
import { mapActions, mapGetters } from 'vuex'
import walletTransactionTable from '@/views/wallet/wallet-transaction-table.vue'
import RechargeModal from '@/components/modals/recharge-modal.vue'
import WithdrawModal from '@/components/modals/withdraw-modal.vue'

export default {
  components: {
    [WalletCard.name]: WalletCard,
    [WithdrawModal.name]: WithdrawModal,
    [RechargeModal.name]: RechargeModal,
    [walletTransactionTable.name]: walletTransactionTable,
    [WalletCardLoading.name]: WalletCardLoading
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      wallet: 'user/view/wallet',
      walletLoading: 'user/view/walletLoading'
    })
  },
  data() {
    return {
      showModal: false
    }
  },
  async mounted() {
    this.doSetUserType('app')
    await this.doFindWallet()
  },
  methods: {
    ...mapActions({
      doSetUserType: 'user/list/doSetUserType',
      doFindWallet: 'user/view/doFindWallet'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.appWallet')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.appWallet')
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  background: #ffffff !important;
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
  padding: 0 !important;
  border-radius: 15px !important;
  margin-bottom: 1rem !important;
}

.content-section {
  padding: 20px !important;
}

.card-actions {
  background: #f8f8f8 !important;
  padding: 1.5rem !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;

  button {
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    text-decoration: underline !important;
    color: #515151 !important;
  }
}

.available-balance {
  padding: 0.25rem !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;

  .balance {
    color: #13b177 !important;
    font-weight: 600 !important;
  }
}

.title {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.9375rem !important;
  line-height: 1.5rem !important;
  color: #000000 !important;
  margin-right: 0.5rem !important;
}

.amount {
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2rem;
  margin-top: 0.5rem;
}
</style>

<style lang="scss" scoped>
@media (max-width: 1439px) {
  .q-card {
    // min-height: calc(80px + (77 - 80) * ((100vw - 320px) / (1440 - 320))) !important;
    border-radius: calc(
      6px + (15 - 6) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .content-section {
    padding: calc(
      12px + (20 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }
  .card-actions {
    padding: calc(
      12px + (24 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .avatar {
    margin-right: calc(
      8px + (12 - 8) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .amount {
    font-size: calc(
      16px + (28 - 16) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .details-label {
    font-size: calc(
      12px + (16 - 12) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    line-height: calc(
      18px + (24 - 18) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }

  .details-value {
    font-size: calc(
      10px + (12 - 10) * ((100vw - 320px) / (1440 - 320))
    ) !important;
    line-height: 18px;
  }
}
</style>
